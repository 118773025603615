.admin-panel {
   /* border-radius: 30px !important; */
   width: 100%;
   height: 100vh;
   background-color: var(--hex-green);
}

.admin-panel > div:first-child {
   /* width: 12%; */
   display: flex;
   flex-direction: column;
   /* align-items: center; */
   justify-content: space-between;
   gap: 5px;
   padding: 2em 0em;
   border-right: 1px solid #19191924;
   background-color: var(--main-green);
}

.admin-panel > div:last-child {
   width: 100%;
   padding: 0;
   overflow-y: auto;
   background-color: white;
}

.admin-panel > div:last-child div.admin-top {
   padding: 2em;
}

.admin-panel > div:first-child a:first-child {
   margin-bottom: 10vh;
   display: flex;
   justify-content: center;
}

.admin-panel > div:first-child a img {
   max-width: 120px;
}

.admin-panel > div:first-child div.menu-link {
   margin-right: 5%;
   border-radius: 0 20px 20px 0;
   cursor: pointer;
   position: relative;
   width: 100%;
}

.admin-panel > div:first-child div.menu-link:before {
   content: '';
   position: absolute;
   left: 0;
   top: 50%;
   transform: translateY(-50%);
   width: 7px;
   border-radius: 0 20px 20px 0;
   border-left: 1px solid rgb(24, 24, 24);
   height: 40px;
   background-color: white;
   opacity: 0;
   /* transition: opacity ease 0.2s; */
}

.admin-panel > div:first-child div.menu-link.selected:before {
   opacity: 1;
}

.admin-panel > div:first-child div.menu-link > div {
   display: flex;
   align-items: center;
   gap: 10px;
   padding: 15px 60px;
}

.admin-panel div.menu-link svg,
.admin-panel div.menu-link p {
   color: rgba(255, 255, 255, 0.37);
}

.admin-panel div.menu-link p {
   font-size: 17px;
   cursor: pointer;
   margin: 0;
}

.admin-panel div.menu-link:not(.selected):hover p,
.admin-panel div.menu-link:not(.selected):hover svg {
   color: rgba(255, 255, 255, 0.596);
}

.admin-panel div.menu-link.selected p,
.admin-panel div.menu-link.selected svg {
   color: white;
}

span.role-status,
span.status {
   text-transform: uppercase;
   border: 1px solid rgb(233, 233, 233);
   padding: 2px 7px;
   border-radius: 5px;
   font-size: 10px;
   min-width: 50px;
   text-align: center;
}

span.role-status.user,
span.status.inactive {
   /* background-color: rgb(236, 236, 236); */
}

span.status.inactive {
   opacity: 0.5;
   /* background-color: var(--hex-red-light); */
}

span.role-status.admin {
   background-color: var(--hex-blue-light);
}
span.role-status.finadmin {
   background-color: var(--hex-orange-light);
}
span.status.active {
   background-color: var(--hex-yellow);
}
