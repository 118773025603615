@font-face {
   font-family: 'Styrene A';
   src: url('../fonts/StyreneA-Medium-Web.woff2') format('woff2'), url('../fonts/StyreneA-Medium-Web.woff') format('woff');
   font-weight: 500;
   font-style: normal;
   font-stretch: normal;
}

@font-face {
   font-family: 'Maison Neue';
   src: url('../fonts/MaisonNeueExtendedWEB-Medium.woff2') format('woff2'), url('../fonts/MaisonNeueExtendedWEB-Medium.woff') format('woff');
   font-weight: 500;
   font-style: normal;
   font-stretch: normal;
}
