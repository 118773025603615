.MuiInputBase-root.MuiInput-root.MuiInput-underline:hover:not(.Mui-disabled, .Mui-error):before {
   border-bottom-width: 1px !important;
   border-color: #7d907d;
}

.MuiDataGrid-cell:focus-within {
   outline: none !important;
}

.projects-list-search {
   border-bottom: 1px solid white !important;
}
