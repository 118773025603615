.section-card {
   background-color: #1e2d2df2;
   /* background-color: var(--hex-grey); */
   display: flex;
   align-items: start;
   justify-content: start;
   gap: 10%;
   border-radius: 10px;
   padding: 20px;
   /* min-height: 50vh; */
   animation: smooth-appear 0.5s ease-in-out;
}

@keyframes smooth-appear {
   0% {
      opacity: 0;
   }

   100% {
      opacity: 1;
   }
}

.section-card.no-height {
   min-height: 10vh;
}

.section-card h2,
.section-card p {
   color: white;
}

.section-card p {
   font-size: 18px;
}

.dashboard h2 {
   font-size: 1em;
   font-weight: 200;
   /*color: var(--hex-text-white);*/
   color: #E6FF31;
   font-family: 'Styrene A';
}

.project-tabs button {
   /* color: var(--hex-text-white); */
   color: white;
   padding: 0.1em 0;
}

.project-tabs button.Mui-selected {
   color: white;
}

.project-tabs .MuiTabs-indicator {
   background-color: white;
}

.MuiTabPanel-root {
   padding: 0 !important;
}