div.log-action {
   border: 1px solid rgb(46, 46, 46);
   color: rgb(46, 46, 46);
   padding: 2px 15px;
   border-radius: 50px;
}

div.log-action.download {
   border: 1px solid rgb(43, 235, 149);
   color: rgb(43, 235, 149);
}

div.log-action.signin {
   border: 1px solid rgb(39, 179, 255);
   color: rgb(39, 179, 255);
}

div.log-action.signup {
   border: 1px solid rgb(224, 24, 255);
   color: rgb(224, 24, 255);
}

div.log-type {
   padding: 2px 15px;
   border-radius: 50px;
}

div.log-type.info {
   /* border: 1px solid rgb(39, 179, 255); */
   color: rgb(39, 179, 255);
}

div.log-type.warning {
   /* border: 1px solid rgb(255, 90, 24); */
   color: rgb(255, 90, 24);
}

a.log-link {
   font-size: 13px;
   color: rgb(30, 30, 30);
   text-decoration: underline;
}

a.log-link:hover {
   color: rgb(34, 168, 202);
}
