.MuiModal-root {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.modal-content {
    border-radius: 5px;
    position: relative;
    min-width: 20vw;
    width: fit-content;
    min-height: 10vh;
    height: fit-content;
    background-color: white;
    padding: 40px;
    text-align: center;
    outline: none !important;
}

.modal-content.smaller {
    min-height: auto;
    max-width: 20vw;
    padding: 30px 50px;
}

.modal-close {
    position: absolute;
    top: 5px;
    right: 5px;
}