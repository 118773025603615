div.hero {
   max-width: calc(100vw - calc(var(--main-padding-x) * 2));
   width: 100%;
   background-repeat: no-repeat;
   background-position: center;
   background-size: cover;
   min-height: 40vh;
   border-top: 2px solid black;
   border-bottom: 2px solid black;
   padding: 20px var(--main-padding-x);
}

.hero-title {
   background-color: var(--main-green);
   box-shadow: 0 1px 10px #000000c2;
   padding: 20px 50px;
   border-radius: 10px;
   max-width: 100%;
}

.hero-title.normal {
   padding: 12px 80px;
}

.hero-title > h1,
.hero-title > h3 {
   font-size: 20px;
   color: white;
}

.hero-title > h3 {
   font-size: 16px;
   font-weight: 200;
   color: var(--main-green-bright);
}

.hero-title.bigger > h1 {
   font-size: 60px;
   border-radius: 0px;
   letter-spacing: -3px;
}

.hero-title.bigger {
   border-radius: 0px;
   width: 100%;
   display: flex;
   flex-direction: column;
}

div.icon-btn-container > * {
   padding: 0;
}

div.icon-btn-container > * {
   color: var(--main-white);
   /* transition: ease all 0.4s; */
   border: 2px solid;
}

div.icon-btn-container:hover > * {
   color: var(--main-green-brighter);
}

@media only screen and (max-width: 800px) {
   .hero-title {
      max-width: 100%;
      padding: 10px 20px;
   }

   .hero-title > h1 {
      font-size: 20px;
   }

   .hero-title.bigger > h1 {
      font-size: 30px;
   }

   .hero-title > h3 {
      font-size: 14px;
   }

   .hero-title.normal {
      padding: 12px 50px;
   }
}
