.project-card {
   width: 60%;
   border-radius: 12px !important;
   position: relative;
   background-size: cover;
   background-position: right;
   background-repeat: no-repeat;
   animation: smoothLoad 0.5s ease-in-out;
   overflow: unset !important;
}

.project-card.no-background {
   background: transparent linear-gradient(90deg, var(--hex-purple-light) 0%, var(--hex-purple) 100%) 0% 0% no-repeat padding-box !important;
}

.card-content {
   z-index: 0;
   position: relative;
}

.project-card .MuiButtonBase-root {
   border-radius: 10px;
}

.project-card .hidden {
   opacity: 0;
   color: #022222;
}

.project-card:hover .hidden {
   opacity: 1;
}

.card-content .green-section {
   position: absolute;
   top: 0;
   right: 0;
   width: calc(100% + 1px);
   height: calc(100% + 1px);
   z-index: -1;
   transition: all ease 0.8s;
   border-radius: 10px;
   /* background: transparent linear-gradient(145deg, var(--hex-purple) 0%, #9d3dd0 40%, #a545d891 100%) 0% 0% no-repeat padding-box !important; */
   /* background: transparent linear-gradient(145deg, #39FF14, #04ABAF91 100%) !important; */
   /* background: transparent linear-gradient(145deg, #E6FF31, #E6FF3191 100%) !important; */
   background: transparent linear-gradient(145deg, #E6FF31, #E6FF31 100%) !important;
}

.card-content:not(.entering) .purple-section.with-image {
   /* clip-path: polygon(0 100%, 0% -100%, 130% 100%); */
   /* clip-path: polygon(0 100%, 0% -150%, 200% 100%); */
}

.project-card:hover .green-section {
   /* clip-path: polygon(0 100%, 0% 100%, 0% 100%); */
   /* opacity: 0; */
   background: transparent linear-gradient(145deg, white, white 100%) !important;
}

.card-action {
   position: relative;
   transition: all ease 0.2s;
}

.skeleton-project-card,
.project-card {
   width: 17vw;
}

.skeleton-project-card,
.project-card .card-content {
   min-height: 100px;
}

.skeleton-project-card {
   padding: 16px;
   border-radius: 10px;
}

.project-card h3 {
   text-transform: uppercase;
   max-width: 80%;
   color: #022222;
}

.project-card .project-card-meta {
   display: flex;
   flex-direction: column;
   justify-content: space-between;
}

.project-card .project-card-meta span {
   text-transform: uppercase;
   color: white;
   font-size: 12px;
}

.project-card .project-card-meta span:first-child {
   opacity: 0.5;
}

.project-card .project-card-meta span:last-child {
   color: white;
   font-weight: 600;
}

div.project-badges {
   display: grid;
   grid-template-columns: repeat(4, 1fr);
   gap: 20px;
}

div.project-badge {
   display: flex;
   flex-direction: column;
   gap: 30px;
   color: white;
   padding: 1em;
   border-radius: 10px;
}

.gradient-blue {
   background: transparent linear-gradient(90deg, var(--hex-blue) 0%, var(--hex-blue-light) 100%) 0% 0% no-repeat padding-box;
}

.gradient-purple {
   background: transparent linear-gradient(90deg, var(--hex-purple) 0%, var(--hex-purple-light) 100%) 0% 0% no-repeat padding-box !important;
}

.gradient-green {
   background-color: transparent #42A050 !important;
}

.gradient-yellow {
   background: transparent linear-gradient(90deg, var(--hex-orange) 0%, var(--hex-yellow) 100%) 0% 0% no-repeat padding-box;
}

.gradient-red {
   background: transparent linear-gradient(90deg, var(--hex-red) 0%, var(--hex-red-light) 100%) 0% 0% no-repeat padding-box;
}

div.project-badge span {}

div.project-badge h3 {
   font-size: 30px;
}

.map {
   position: relative;
   width: 100%;
   display: flex;
   flex-direction: row;
   background-color: var(--foursquare-background-color);
   height: 88vh;
}

.map:not(.full-screen) {
   border-radius: 10px;
   overflow: hidden;
}

.map .side-nav {
   width: 0;
   visibility: hidden;
}

.foursquare-root>div:first-child>div:nth-child(2) {
   margin: 0;
}

.foursquare-root>div:first-child>div:nth-child(2),
.map .kepler-gl {
   width: 100% !important;
}

.map .label {
   background-color: transparent;
}

.snapshot-button,
.snapshot-div {
   display: inline-flex;
   align-items: center;
   justify-content: center;
   background-color: var(--main-green);
   color: var(--main-white);
   border-radius: 15px;
}

.snapshot-div {
   box-shadow: 0 2px 5px rgba(0, 0, 0, 0.897);
   /* Add a box shadow effect */
}

.snapshot-div:hover {
   transform: scale(1.01);
   filter: brightness(1.2);
}

.snapshot-button {
   width: 100%;
   cursor: pointer;
   padding: 6px 16px;
}

.snapshot-panel {
   background-color: var(--foursquare-background-color);
   border: 1px solid;
   width: 4%;
}

.snapshot-title {
   overflow: hidden;
   text-overflow: ellipsis;
   white-space: nowrap;
}

.map.full-screen {
   position: fixed;
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
   z-index: 1000;
   /* Set a high z-index to ensure the map is on top of other elements */
   width: 100vw;
   height: 100vh;
}

.map.extend {
   height: 100vh !important;
}

.snapshot-panel.full-screen {
   width: 3%;
}

.mapbox-attribution-container,
.sc-bUIkmT,
.sc-dsaGNW {
   display: none !important;
}

div.update-thumbnail {
   transition: ease all 0.3s;
   position: relative;
   background-color: black;
   background-repeat: no-repeat;
   background-position: center;
   background-size: contain;
   background-color: white;
   border: 1px solid #c4c4c4;
   border-radius: 10px;
}

div.update-thumbnail:hover img {
   filter: brightness(0.4);
}

div.update-thumbnail:hover p.no-thumbnail {
   opacity: 0;
}

div.update-thumbnail div.thumbnail-actions {
   transition: ease all 0.3s;
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
   opacity: 0;
   background-color: rgba(255, 255, 255, 0.687);
   border-radius: 50px;
}

div.update-thumbnail div.thumbnail-actions:hover {
   background-color: white;
}

div.update-thumbnail:hover div.thumbnail-actions {
   opacity: 1;
}

div.project-single-content {
   max-height: 100vh;
   overflow-y: auto;
}

div.project-single-content>div {
   padding: 1em;
}

.foursquare-root a {
   display: none;
}

div.map-loading {
   position: absolute;
   left: 50%;
   top: 50%;
   transform: translate(-50%, -50%);
}

div.hero-badge {
   background-color: var(--main-green);
   padding: 10px 30px;
   border-radius: 10px;
}

div.hero-badge p:first-child {
   font-size: 23px;
   font-weight: 500;
   color: white;
}

div.hero-badge p:last-child {
   font-size: 15px;
   font-weight: 400;
   color: var(--main-green-bright);
}

div.map-control {
   transform: translateX(0) translateY(50px);
}

button.open-snapshots-btn,
button.fullscreen-btn {
   position: absolute !important;
   right: 20px;
   bottom: 0;
}

.open-snapshots-btn:not(:disabled) {
   top: 15px;
   border: 2px solid var(--main-green-bright) !important;
   padding: 0px !important;
}

.fullscreen-btn:not(:disabled) {
   position: absolute !important;
   bottom: 15px;
   padding: 5px !important;
}

.side-bar__close {
   background-color: transparent !important;
   cursor: pointer;
   border: none;
   outline: none;
   width: auto !important;
   height: auto !important;
   border-radius: 20px !important;
   transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
   top: 15px !important;
   right: -64px !important;
   padding: 5px;
   border: 2px solid var(--main-green-bright);
}

.side-bar__close:has(svg[style*='transform: rotate(180deg)']) {
   right: 15px !important;
}

.side-bar__close:hover,
.side-bar__close:focus {
   background-color: rgba(230, 255, 49, 0.04) !important;
}

.side-bar__close svg {
   fill: var(--main-green-bright);
   width: 24px !important;
   height: 24px !important;
}

.close-snapshots-btn {
   padding: 0px !important;
   position: absolute !important;
   top: 15px;
   left: 15px;
   border: 2px solid var(--main-green-bright) !important;
}

.instances {
   position: relative;
   display: grid;
   grid-template-columns: repeat(4, 1fr);
   grid-gap: 20px;
}

.instance-card {
   /* width: 24%; */
   border-bottom: 2px solid #e8e8e8;
}

.instance-card.approved {
   border: 3px solid #8bd186;
}

.instance-card.hidden {
   opacity: 0.8;
}

.instances .MuiPaper-root,
.instance-card .instance-card-content {
   background-color: #e8e8e8;
}

.published-map {
   border-bottom: 3px solid #8bd186 !important;
}

div.chart-tootltip {
   display: flex;
   flex-direction: column;
   padding: 5px 10px;
   background-color: white;
   border: 2px solid var(--main-green);
}

div.chart-tootltip h3 {
   margin-bottom: 10px;
}

div.loading-chart {
   background: #eee;
   background: linear-gradient(110deg, #ececec 8%, #f7f7f7 18%, #ececec 33%);
   border-radius: 5px;
   background-size: 200% 100%;
   animation: 1.5s shine linear infinite;
}

span.loading-chart {
   transform: none !important;
}

div.project-nav {
   min-width: 18em;
   min-height: 100vh;
   max-height: 100vh;
   overflow-y: auto;
}

div.project-nav>div {
   padding: 2em 0;
}

div.project-nav img {
   max-width: 150px;
}

div.project-breadcrumbs>* {
   color: white;
   font-weight: 700 !important;
   text-decoration: none;
}

div.project-breadcrumbs>a:hover {
   color: var(--hex-text-white);
}

div.project-nav .subcategory {
   border-radius: 0 50px 50px 0;
   /* padding: 0 50px 0 0; */
   padding: 3px 40px 3px 0;
   font-weight: 700;
   transition: ease all 0.4s;
}

div.project-nav .subcategory.hidden {
   cursor: default;
}

div.project-nav .subcategory.hidden span {
   /* filter: blur(0.2px); */
   opacity: 0.4;
}

div.project-nav .subcategory:not(.hidden):hover {
   background-color: #4b416345;
}

div.project-nav .category span {
   font-weight: 700;
   color: white;
}

div.project-nav .subcategory span,
div.project-nav .subcategory .MuiListItemIcon-root,
div.project-nav .subcategory .MuiSvgIcon-root {
   font-weight: 500;
   font-size: 15px;
   color: var(--hex-text-white-light);
}

div.project-nav .subcategory .MuiListItemIcon-root {
   min-width: auto;
}

div.project-nav .subcategory.active {
   background: transparent linear-gradient(90deg, var(--hex-purple) 0%, var(--hex-purple-light) 100%) 0% 0% no-repeat padding-box !important;
}

ul.subnav-tooltip {
   padding: 0 20px;
}

ul.subnav-tooltip li {
   /* font-size: 15px; */
}

div.project-nav .subcategory.active span {
   color: white;
}

div.market-assumptions table h6,
div.market-assumptions table h2 {
   color: var(--hex-yellow);
}

div.market-assumptions .MuiTableContainer-root {
   /* width: auto; */
}

div.market-assumptions table th,
div.market-assumptions table td {
   color: white;
   border-bottom: 1px solid rgb(66, 66, 66);
}

div.market-assumptions table a {
   color: var(--hex-blue-light);
}

div.scenarios-comparison table {
   border-collapse: separate !important;
}

div.scenarios-comparison thead tr th,
div.scenarios-comparison thead tr th a {
   font-size: 15px;
   color: var(--hex-yellow) !important;
}

div.scenarios-comparison thead tr th a:hover {
   color: var(--hex-yellow-light) !important;
}

div.scenarios-comparison thead tr td:first-child,
div.scenarios-comparison tbody tr td:first-child {
   font-size: 15px;
   color: var(--hex-blue-light);
}

div.scenarios-comparison thead tr th:nth-child(2) {
   border: 2px solid var(--hex-yellow);
   border-bottom: 1px solid rgba(66, 66, 66, 0.363);
   border-radius: 10px 10px 0 0;
}

div.scenarios-comparison tbody tr td:nth-child(2) {
   border: 2px solid var(--hex-yellow);
   border-top: none;
   border-bottom: 1px solid rgba(66, 66, 66, 0.363);
}

div.scenarios-comparison tbody tr:last-child td:nth-child(2) {
   border-bottom: 2px solid var(--hex-yellow);
   border-radius: 0 0 10px 10px;
}

div.scenarios-comparison thead tr th:nth-child(2),
div.scenarios-comparison tbody tr:last-child td:nth-child(2),
div.scenarios-comparison tbody tr td:nth-child(2) {
   background-color: var(--main-green);
}

input.projects-list-search {
   border: none;
   border-bottom: 1px solid rgb(148, 148, 148) !important;
   background: none;
   font-family: 'Roboto', sans-serif;
   outline: none;
   color: white;
   font-size: 16px;
   padding: 0 0 5px 0;
   transition: all ease 0.4s;
}

input.projects-list-search:hover,
input.projects-list-search:focus {
   border-color: white !important;
}

@keyframes shine {
   to {
      background-position-x: -200%;
   }
}

.chart-section {
   background-color: white;
   padding: 5%;
}

.net-value-equation {
   min-width: 25%;
}

.net-value-equation table {
   /* border: 1px solid white; */
   border-radius: 10px !important;
   border-collapse: unset !important;
}

.net-value-equation table tr:not(.underline) td,
.net-value-equation table tr:not(.underline) th {
   border: none;
}

.net-value-equation table tr td,
.net-value-equation table tr th {
   color: white;
}

.net-value-equation table tr:last-child td {
   font-weight: 800;
}

.net-value-equation table tr td:last-child,
.net-value-equation table tr th:last-child {
   text-align: right;
}

.map-placeholder {
   background-image: url('/src/static/assets/images/map-placeholder.png');
   background-repeat: no-repeat;
   background-position: center;
   background-size: 150%;
   border-radius: 10px;
   position: relative;
   cursor: pointer;
   transition: all ease 0.3s;
}

.map-placeholder:hover {
   background-size: 155%;
}

.notes-container {
   min-height: 25px;
   margin-top: 3px;
}

.notes-container .notes {
   font-size: 12px;
   line-height: 12px;
   color: grey;
}

.notes-container a.add-note {
   font-size: 12px;
   color: grey;
   text-decoration: solid underline;
}

@media only screen and (max-width: 1500px) {
   .project-card {
      width: 80%;
   }

   .mapDrawer .MuiDrawer-paper {
      width: 30% !important;
   }

   .instances {
      grid-template-columns: repeat(3, 1fr);
   }
}

@media only screen and (max-width: 1200px) {
   .project-card {
      border-radius: 0%;
      width: 100%;
   }

   .mapDrawer .MuiDrawer-paper {
      width: 40% !important;
   }

   .snapshot-panel {
      width: 5%;
   }

   .snapshot-panel.full-screen {
      width: 7%;
   }

   .instances {
      grid-template-columns: repeat(2, 1fr);
   }
}

@media only screen and (max-width: 900px) {
   .mapDrawer .MuiDrawer-paper {
      width: 60% !important;
   }

   .snapshot-panel {
      width: 6%;
   }

   .snapshot-panel.full-screen {
      width: 9%;
   }

   .instances {
      grid-template-columns: repeat(1, 1fr);
   }
}

@media only screen and (max-width: 400px) {
   .snapshot-panel {
      width: 7%;
   }

   .snapshot-panel.full-screen {
      width: 10%;
   }
}