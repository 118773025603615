@import url('./static/styles/fonts.css');
@import url('./static/styles/layout.css');
@import url('./components/shared/ui/modals/modal.css');
@import url('./static/styles/material-override.css');
@import url('./static/styles/hero.css');
@import url('./static/styles/project.css');
@import url('./components/admin/admin.css');
@import url('./static/styles/logs.css');

@import url('./static/styles/design-v2.css');

:root {
   --main-green: #022222;
   --main-green-bright: #e6ff31;
   --main-blue-light: #00acb0;
   --main-green-brighter: #eeff71;
   --main-blue: #bee6e6;
   --main-white: #f7f7f7;
   --main-grey: #c9d4c9;
   --foursquare-background-color: #0e111a;

   --hex-text-white: #bee6e6;
   --hex-text-white-light: #f7f7f75f;

   --hex-green: #022222;
   --hex-green-light: #053c3c;

   --hex-grey: #1e2d2d;
   --hex-grey-clear: #749074;

   --hex-red: #ff5449;
   --hex-red-light: #ec6666;

   --hex-yellow: #e6ff31;

   --hex-orange: #fea900;
   --hex-orange-light: #fbd27f;

   --hex-blue: #3946b1;
   --hex-blue-light: #bee6e6;

   --hex-teal: #008080;

   --hex-purple: #4b4163;
   --hex-purple-light: #a545d8;

   --main-padding-x: 5em;
   --main-padding-y: 5em;
}

html {
   background-color: var(--hex-green);
}

body {
   overflow-y: auto !important;
}

h1,
h2,
h3,
img,
a,
p,
svg {
   transition: all ease 0.4s;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
   margin: 0;
   color: var(--hex-green);
   font-family: 'Roboto', sans-serif;
}

h3 {
   font-size: 1.2em;
   font-weight: 600;
   color: white;
}

.small-text {
   font-size: 12px !important;
}

a,
button {
   cursor: pointer;
}

a {
   color: var(--main-green);
}

.yellow {
   color: var(--hex-yellow);
}

.small-link {
   font-size: 12px;
}

label>span {
   color: rgb(92, 92, 92);
}

.top {
   /* background-color: var(--main-green); */
   padding: 5px 10px 5px 10px;
   /* border-bottom: 1px solid var(--main-green-bright); */
}

.nav-logo {
   width: auto;
   height: 35px;
}

.App {
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   min-height: 100vh;
}

main {
   /* padding: var(--main-padding-y) var(--main-padding-x); */
}

div.container {
   padding: var(--main-padding-y) var(--main-padding-x);
}

.bottom {
   /* background-color: var(--main-green); */
   /* min-height: 7vh; */
   position: relative;
   /* margin-top: 10vh; */
   display: flex;
   justify-content: center;
   align-items: center;
   overflow: hidden;
}

.bottom p {
   font-size: 60px;
   color: white;
   max-width: 50vw;
   font-family: 'Styrene A';
}

.bottom svg {
   transform: translateY(20%);
   max-width: 20vw;
   height: auto;
   opacity: 0.8;
}

.bottom.small {
   position: fixed;
   bottom: 0;
   right: 0;
   z-index: -1;
}

.bottom.small svg {
   max-width: 20vw;
   transform: translateY(20%) translateX(10%);
}

div.breadcrumb {
   gap: 10px;
}

div.breadcrumb>a {
   color: var(--main-green);
}

.simple-link {
   color: var(--main-green);
}

.simple-link[color='secondary'] {
   color: var(--main-green-bright);
}

::-webkit-scrollbar {
   width: 5px;
   height: 10px;
}

/* Style the scrollbar thumb (the draggable portion of the scrollbar) */
::-webkit-scrollbar-thumb {
   background-color: rgba(56, 56, 56, 0.582);
   /* border-radius: 10px; */
}

/* Style the scrollbar track (the non-draggable portion of the scrollbar) */
::-webkit-scrollbar-track {
   /* background-color: var(--hex-green); */

   /* border-radius: 10px; */
}

div.under-maintenance {
   position: fixed;
   top: 0;
   left: 0;
   width: 100vw;
   height: 100vh;
   background-color: var(--hex-green);
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
   z-index: 999;
}

div.under-maintenance * {
   color: white;
}

div.status {
   border: 2px solid grey;
   border-radius: 20px;
   padding: 0px 12px;
   margin: 0;
   font-size: 11px;
   letter-spacing: 1px;
   min-width: 80px;
   text-align: center;
   width: max-content;
}

div.static-save-div {
   background-color: white;
   position: fixed;
   z-index: 999;
   padding: 0;
   bottom: 20px;
   right: 30px;
   border-radius: 20px;
}

div.pagination {
   gap: 5px;
   transition: all ease 0.3s;
}

div.pagination.hide {
   transition: none;
   opacity: 0;
}

div.pagination button.pagination-item {
   border: none;
   background-color: var(--hex-green);
   padding: 7px 10px;
   border-radius: 50px;
   color: white;
   opacity: 0.6;
   transition: all ease 0.3s;
}

div.pagination button.pagination-item:not(.active):hover {
   opacity: 1;
}

div.pagination button.pagination-item.active {
   background-color: var(--hex-purple-light);
   opacity: 1;
}

div.invite-code {
   color: grey;
   font-size: 12px;
}

@media only screen and (max-width: 1200px) {
   .bottom {
      /* margin-top: 10vh; */
   }

   .bottom img.footer-top-img {
      /* top:-6.8vw; */
   }
}

@keyframes smoothLoad {
   0% {
      opacity: 0;
   }

   100% {
      opacity: 1;
   }
}